import React from 'react';
import { FaTicketAlt } from 'react-icons/fa';

const SuggestedQuestions = ({ suggestedQuestions, setShowChat, sendMessage, setShowForm }) => {
  return (
    <div className="flex flex-col items-center mt-10 text-center">
      <h2 className="text-3xl font-bold text-gray-800 mb-8 self-center">
        Come posso aiutarti oggi?
      </h2>
      <div className="flex flex-wrap justify-center gap-8">
        {suggestedQuestions.map((question, index) => (
          <button
            key={index}
            onClick={() => {
              setShowChat(true);
              if (question === 'Voglio comprare un biglietto per un certo percorso') {
                setShowForm(true);
              } else {
                sendMessage(question);
              }
            }}
            className="flex items-center space-x-2 px-6 py-4 border rounded-lg shadow-lg text-lg font-semibold text-gray-800 bg-white hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-[#00a481]"
          >
            {question === 'Voglio comprare un biglietto per un certo percorso' && (
              <FaTicketAlt className="text-green-500 text-2xl" />
            )}
            <span>{question}</span>
          </button>
        ))}
      </div>
    </div>
  );
};

export default SuggestedQuestions;
