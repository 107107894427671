import React from 'react';
import { FaMapMarkerAlt, FaFlagCheckered } from 'react-icons/fa';

const TicketForm = ({ formState, onFormChange, handleFormSubmit, handleFormCancel}) => (
  <div className="flex flex-col space-x-4 mt-4 p-4 rounded-lg max-w-1xl mx-auto">
    <div className="relative max-w-2xl px-4 py-3 rounded-lg border border-gray-300 dark:border-gray-700 shadow-md bg-white dark:bg-gray-800">
      <h3 className="text-lg font-bold text-gray-800 dark:text-gray-200 mb-3">
        Inserisci i dettagli del tuo viaggio
      </h3>
      <div className="space-y-3">
        <div className="flex items-center space-x-3">
          <FaMapMarkerAlt className="text-red-500 dark:text-red-400 text-xl" />
          <input
            type="text"
            name="startLocation"
            value={formState.startLocation}
            onChange={onFormChange}
            placeholder="Inserisci la posizione di partenza: Via guglielmo marconi 71, bologna"
            className="w-full px-3 py-2 border border-gray-300 dark:border-gray-700 rounded-md text-gray-800 dark:text-gray-200 bg-white dark:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-[#00a481] dark:focus:ring-[#00ffb3]"
          />
        </div>
        <div className="flex items-center space-x-3">
          <FaFlagCheckered className="text-black dark:text-gray-200 text-xl" />
          <input
            type="text"
            name="endLocation"
            value={formState.endLocation}
            onChange={onFormChange}
            placeholder="Inserisci la destinazione: Via Emilia Interna, 25, Castel Bolognese"
            className="w-full px-3 py-2 border border-gray-300 dark:border-gray-700 rounded-md text-gray-800 dark:text-gray-200 bg-white dark:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-[#00a481] dark:focus:ring-[#00ffb3]"
          />
        </div>
      </div>
      <div className="flex justify-end space-x-3 mt-4">
        <button
          onClick={handleFormCancel}
          className="px-5 py-2 bg-gray-400 text-white rounded-md shadow-md hover:bg-gray-500 focus:outline-none focus:ring-2 focus:ring-gray-400"
        >
          Annulla
        </button>
        <button
          onClick={handleFormSubmit}
          className="px-5 py-2 bg-[#009973] text-white rounded-md shadow-md hover:bg-[#00a481] dark:bg-[#00b386] dark:hover:bg-[#00ffb3] focus:outline-none focus:ring-2 focus:ring-[#00a481] dark:focus:ring-[#00ffb3]"
        >
          Invia
        </button>
      </div>
    </div>
  </div>
);

export default TicketForm;
