import React, { useState, useRef, useEffect, useCallback } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import Layout from './layout';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import remarkBreaks from 'remark-breaks';
import SolutionCard from './card/travel_card';
import SuggestedQuestions from './Suggessed/suggessed';
import TicketForm from './form/TicketForm';
import { FaArrowLeft } from 'react-icons/fa';
import { FaRegTrashAlt } from "react-icons/fa";

const chatApiUrl = process.env.REACT_APP_BASE_URL;

const FormattedText = ({ children }) => (
  <ReactMarkdown
    remarkPlugins={[remarkGfm, remarkBreaks]}
    components={{
      li: ({ node, ...props }) => <li className="my-2" {...props} />,
      ol: ({ node, ...props }) => <ol className="list-disc my-4" {...props} />,
    }}
  >
    {children}
  </ReactMarkdown>
);

const App = () => {
  const [messageHistory, setMessageHistory] = useState([]);
  const [error, setError] = useState(null);
  const [sucess,setSucess] = useState(null);
  const [loading, setLoading] = useState(false);
  const [input, setInput] = useState('');
  const [showChat, setShowChat] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [formState, setFormState] = useState({ startLocation: '', endLocation: '' });
  const [showSuggestedQuestions, setShowSuggestedQuestions] = useState(true);

  const bottomRef = useRef(null);
  const textareaRef = useRef(null);
  const disabled = loading || input.trim() === '';

  const suggestedQuestions = [
    'Voglio comprare un biglietto per un certo percorso',
    'Cosa succede se cambio scuola?',
    "Quando si può richiedere il cambio dell'abbonamento?",
    "L'abbonamento è valido anche nei festivi?",
    'Quali sono le fasce di età coinvolte?',
    'Dove posso richiedere assistenza?',
  ];

  useEffect(() => {
    bottomRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messageHistory]);

  const adjustTextareaHeight = useCallback(() => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = 'auto';
      const newHeight = Math.min(textarea.scrollHeight, 160);
      textarea.style.height = `${newHeight}px`;
      textarea.style.overflowY = newHeight === 160 ? 'auto' : 'hidden';
    }
  }, []);

  useEffect(() => {
    adjustTextareaHeight();
  }, [input, adjustTextareaHeight]);

  const transformResponseData = (plans) =>
    Object.entries(plans).map(([planId, sections]) => ({
      planId,
      sections,
    }));

  const sendMessage = async (message = input) => {
    if (!message?.trim()) {
      return;
    }

    setShowSuggestedQuestions(false);
    setShowForm(false);
    setShowChat(true); 
    setLoading(true);
    setError(null);

    setMessageHistory((prevMessages) => [
      ...prevMessages,
      { role: 'user', content: message },
    ]);
    setInput('');

    try {
      const response = await fetch(chatApiUrl, {
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify({ content: message }),
        credentials: 'include',
      });

      const data = await response.json();

      if (response.ok) {
        if (typeof data.response === 'string') {
          try {
            data.response = JSON.parse(data.response);
          } catch (e) {
            console.error('Failed to parse data.response as JSON:', e);
          }
        }

        if (data && data.response.plans) {
          const { plans, starting_location, ending_location } = data.response.plans;
          setMessageHistory((prevMessages) => [
            ...prevMessages,
            {
              role: 'assistant',
              type: 'card',
              plans: transformResponseData(plans),
              startingLocation: starting_location,
              endingLocation: ending_location,
            },
          ]);
        } else if (data.response) {
          setMessageHistory((prevMessages) => [
            ...prevMessages,
            {
              role: 'assistant',
              content: data.response,
            },
          ]);
        } else {
          setError('Unexpected response format');
        }
      } else {
        setError(data.detail || 'An error occurred');
      }
    } catch (error) {
      console.error('Network error:', error);
      setError('Network error');
    } finally {
      setLoading(false);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !e.shiftKey && !disabled) {
      e.preventDefault();
      sendMessage();
    }
  };

  const handleInputChange = (e) => {
    setInput(e.target.value);
    setError(null);
  };

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    
    setFormState((prevState) => ({ ...prevState, [name]: value }));
    setShowChat(false);
  };

  const handleFormSubmit = () => {
    if (formState.startLocation.trim() && formState.endLocation.trim()) {
      const query = `Voglio comprare un biglietto da ${formState.startLocation}, ${formState.endLocation}.`;
      sendMessage(query);
      setShowForm(false);
      // setShowChat(true);
      setShowSuggestedQuestions(false);
      setShowChat(true);
    }
  };

  const handleFormCancel = () => {
    console.log("cancel")
    setShowSuggestedQuestions(true);
    setShowChat(false)
    setShowForm(false);
    
  };

  const handleDeleteChat = async () => {
    try {
      const response = await fetch(`${chatApiUrl}delete_chat/`, {
        method: 'DELETE',
        credentials: 'include',
      });

      if (response.ok) {
        setMessageHistory([]);
        setSucess(true);
        setTimeout(() => {
          setSucess(false);
        }, 2000);
        setShowSuggestedQuestions(true);
        setShowChat(false);
        setShowForm(false);
      } else {
        setError('Failed to delete chat history');
      }
    } catch (error) {
      console.error('Error deleting chat:', error);
      setError('Network error');
    }
  };

  return (
    <Router>
      <Layout />
      
      <div className="container mx-auto px-6 flex flex-col items-center" style={{ height: 'calc(98vh - 64px)' }}>
        <div className="flex justify-between items-center w-full m-4">
          <button
            onClick={() => {
              setShowSuggestedQuestions(true);
              setShowChat(false);
              setShowForm(false);
            }}
            className="flex items-center space-x-0 px-2 py-2 bg-white text-[#00a481] rounded-md shadow-md hover:bg-[#00a481] hover:text-white  focus:outline-none focus:ring-2 focus:ring-blue-400"
          >
            <FaArrowLeft className="text-[#00a481] hover:text-white text-lg" />
            <span className="sr-only">Torna alle domande suggerite</span>
          </button>
        </div>
        {showSuggestedQuestions && !showChat ? (
          <div className="flex-grow">
            <SuggestedQuestions
              suggestedQuestions={suggestedQuestions}
              setShowChat={setShowChat}
              setShowForm={setShowForm}
              sendMessage={(message) => {
                if (message === 'Voglio comprare un biglietto per un certo percorso') {
                  setShowChat(false);
                  setShowForm(true);
                } else {
                  sendMessage(message);
                  setShowChat(true);
                }
                
              }}
              
            />
          </div>
        ) : (
          <div className="flex-grow w-full max-w-4xl mx-auto">
            {showForm && (

              <TicketForm
                formState={formState}
                showForm={showForm}
                onFormChange={handleFormChange}
                handleFormSubmit={handleFormSubmit}
                handleFormCancel={handleFormCancel}
              />
            )}

            <div className="flex flex-col space-y-4 p-6 rounded-lg max-w-4xl mx-auto mt-6">
              {messageHistory.map((message, index) => {
                const isUser = message.role === 'user';
                return (
                  <div key={index} className={`flex ${isUser ? 'justify-end' : 'justify-start'}`}>
                    {message.type === 'card' ? (
                      <div className="relative max-w-4xl">
                        {message.plans.map((plan, idx) => (
                          <SolutionCard
                            key={plan.planId}
                            plan={plan.sections}
                            index={idx}
                            startingLocation={message.startingLocation}
                            endingLocation={message.endingLocation}
                          />
                        ))}
                      </div>
                    ) : (
                      <div
                        className={`relative max-w-3xl px-6 py-3  rounded-lg ${
                          isUser
                            ? 'bg-[#00a481] text-white rounded-br-none'
                            : 'bg-gray-100 text-gray-800 rounded-bl-none'
                        }`}
                      >
                        <FormattedText>{message.content}</FormattedText>
                      </div>
                    )}
                  </div>
                );
              })}
              <div ref={bottomRef}></div>
            </div>
          </div>
        )}


        <div className="sticky bottom-0 left-0 w-full p-4 bg-white max-w-4xl mx-auto flex items-center space-x-4">
          <div className="relative w-full">
            {(error && (
              <div className="animate-fadeIn p-2 px-4 w-full bg-red-500 text-white self-center rounded-md mb-2">
                <FormattedText>{error}</FormattedText>
              </div>
            )) ||
              (sucess && (
                <div className="animate-fadeIn p-2 px-4 w-full bg-[#00a481] text-white self-center rounded-md mb-2">
                  <FormattedText>{"Chat history has been deleted"}</FormattedText>
                </div>
              ))}

            <textarea
              ref={textareaRef}
              value={input || ''}
              className="w-full pr-12 appearance-none border border-gray-300 rounded-md py-3 text-gray-700 leading-tight px-4 focus:outline-none focus:ring-2 focus:ring-[#00a481] resize-none min-h-[2.5rem] max-h-40"
              onChange={handleInputChange}
              onKeyDown={handleKeyDown}
              rows="1"
              placeholder="Scrivi un messaggio..."
              disabled={loading || showForm}
            />

            {loading && (
              <div
                className="animate-fadeIn absolute bottom-5 right-14 inline-block h-5 w-5 animate-spin rounded-full border-2 border-solid border-[#00a481] border-r-transparent"
                role="status"
              />
            )}

            <button
              className={`absolute right-2 bottom-3 flex justify-center items-center p-2 rounded-md focus:outline-none focus:ring-2 focus:ring-[#00a481] ${
                disabled
                  ? 'bg-gray-300 text-white cursor-not-allowed'
                  : 'bg-[#00a481] text-white hover:bg-[#009973]'
              }`}
              onClick={() => sendMessage()}
              disabled={disabled}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="17"
                height="17"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="lucide lucide-circle-arrow-up"
              >
                <circle cx="12" cy="12" r="10" />
                <path d="m16 12-4-4-4 4" />
                <path d="M12 16V8" />
              </svg>
            </button>
          </div>

          <button
            className="flex justify-center items-center p-3 mb-0 rounded-md bg-red-500 text-white hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-400"
            onClick={handleDeleteChat}
            
          >
            <FaRegTrashAlt className='text-white'/>
          </button>
        </div>
      </div>
    </Router>
  );
};

export default App;
