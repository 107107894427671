// travel_card.jsx
import React from 'react';
import PropTypes from 'prop-types';
import { FaMapMarkerAlt, FaTrain, FaBus, FaFlagCheckered } from 'react-icons/fa';

const SolutionCard = ({ plan, index, startingLocation, endingLocation }) => {
  const renderSection = (section, idx) => {
    if (section.type === 'urban') {
      return (
        <div key={idx} className="flex items-center space-x-4 mt-4">
          <div>
            <FaBus className="text-blue-500 dark:text-blue-300 text-2xl" />
          </div>
          <div className="flex flex-col">
            <div className="font-semibold text-lg text-gray-800 dark:text-gray-200">
              Area Urbana: {section.urban_area_name} (Codice: {section.urban_area_code})
            </div>
            <div className="text-sm text-gray-500 dark:text-gray-400">{section.agency}</div>
          </div>
        </div>
      );
    }

    return (
      <div key={idx} className="flex items-center space-x-4 mt-4">
        <div>
          {section.type === 'train' ? (
            <FaTrain className="text-green-500 dark:text-green-300 text-2xl" />
          ) : (
            <FaBus className="text-blue-500 dark:text-blue-300 text-2xl" />
          )}
        </div>
        <div className="flex flex-col">
          <div className="font-semibold text-lg text-gray-800 dark:text-gray-200">
            {section.starting_station_name
              ? section.starting_station_name
              : 'Zona ' + section.starting_zone_code}{' '}
            ➞{' '}
            {section.ending_station_name
              ? section.ending_station_name
              : 'Zona ' + section.ending_zone_code}
          </div>
          <div className="text-sm text-gray-500 dark:text-gray-400">{section.agency}</div>
          {section.notes && section.notes.length > 0 && (
            <div className="mt-2 space-y-1">
              {section.notes.map((note, idx) => (
                <div
                  key={idx}
                  className="bg-blue-100 dark:bg-blue-900 text-blue-700 dark:text-blue-300 text-sm p-2 rounded-md"
                >
                  {note}
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    );
  };

  return (
    <div className="border dark:border-gray-700 rounded-lg shadow-lg p-6 bg-white dark:bg-gray-800 mb-6 max-w-3xl mx-auto">
      <div className="flex items-center mb-6 space-x-2">
        <div className="text-xl font-bold text-gray-800 dark:text-gray-200">
          Soluzione {index + 1}
        </div>
      </div>
      <div className="flex items-center space-x-2 mb-6">
        <FaMapMarkerAlt className="text-red-500 dark:text-red-400" />
        <div className="text-md text-gray-800 dark:text-gray-200 font-medium">
          {startingLocation.street_name} {startingLocation.house_number}, {startingLocation.city}
        </div>
      </div>

      <div className="border-l-2 border-gray-300 dark:border-gray-600 pl-4">
        {plan.map((section, idx) => renderSection(section, idx))}
      </div>

      <div className="flex items-center space-x-2 mt-6">
        <FaFlagCheckered className="text-black dark:text-gray-200" />
        <div className="text-md text-gray-800 dark:text-gray-200 font-medium">
          {endingLocation.street_name} {endingLocation.house_number}, {endingLocation.city}
        </div>
      </div>
    </div>
  );
};

SolutionCard.propTypes = {
  plan: PropTypes.arrayOf(
    PropTypes.shape({
      agency: PropTypes.string.isRequired,
      index: PropTypes.number,
      type: PropTypes.string.isRequired,
      starting_zone_code: PropTypes.string,
      starting_station_name: PropTypes.string,
      ending_station_name: PropTypes.string,
      ending_zone_code: PropTypes.string,
      urban_area_code: PropTypes.string,
      urban_area_name: PropTypes.string,
      notes: PropTypes.arrayOf(PropTypes.string),
    })
  ).isRequired,
  index: PropTypes.number.isRequired,
  startingLocation: PropTypes.shape({
    street_name: PropTypes.string.isRequired,
    house_number: PropTypes.string.isRequired,
    city: PropTypes.string.isRequired,
  }).isRequired,
  endingLocation: PropTypes.shape({
    street_name: PropTypes.string.isRequired,
    house_number: PropTypes.string.isRequired,
    city: PropTypes.string.isRequired,
  }).isRequired,
};

export default SolutionCard;
